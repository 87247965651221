:root {
  --font-1: "Big-Caslon";
  --font-2: "AkkuratLL-Regular";
}

@font-face {
  font-family: Big-Caslon;
  src: url("../../clients/#{$client}/fonts/BigCaslon.ttf") format("truetype");
}
@font-face {
  font-family: AkkuratLL-Regular;
  src: url("../../clients/#{$client}/fonts/AkkuratLLRegular.otf") format("opentype");
}
