:root {
  --focus-text-color: #2B1665;
  --focus-ring-color: #952376;
}.base {
  
  
  background-color: #F1E7E1;
}
.baseWhite {
  
  
  background-color: #FFF7F2;
}
.baseBlack {
  
  
  background-color: #333333;
}
.backgroundGrayBackground {
  
  
  background-color: #F4F4F4;
}
.accentYellow {
  
  
  background-color: #ED9A00;
}
.accentLight {
  
  
  background-color: #952376;
}
.accentDark {
  
  
  background-color: #2B1665;
}
.accentPinkBorderedClearBackground {
  
  
  border-width: 1px;
  border-style: solid;
  border-color: #EA909D;
}
.circleAccentYellowBorderedClearBackground {
  
  
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #ED9A00;
}
.accentLightBorderedClearBackground {
  &:active {
    border-color: #952376;
  }
  
  border-width: 2px;
  border-style: solid;
  border-color: #952376;
}
.roundedAccentYellowBackground {
  
  
  border-radius: 3px;
  background-color: #ED9A00;
}
.roundedAccentYellowBorderedAccentYellowBackground {
  &:active {
    border-color: #ED9A00;
  }
  &:disabled {
    border-color: #989898;
  }
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  border-color: #ED9A00;
  background-color: #ED9A00;
}
.roundedBaseWhiteBorderedBaseWhiteBackground {
  
  
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  border-color: #FFF7F2;
  background-color: #FFF7F2;
}
.roundedAccentOrangeBorderedBaseWhiteBackground {
  
  
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  border-color: #E46936;
  background-color: #FFF7F2;
}
.accentDarkBorderedClearBackground {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #2B1665;
}
.roundedAccentDarkBorderedClearBackground {
  
  
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  border-color: #2B1665;
}
.roundedAccentYellowBorderedBaseWhiteBackground {
  &:active {
    border-color: #ED9A00;
  }
  
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  border-color: #ED9A00;
  background-color: #FFF7F2;
}
.circleClearBackground {
  
  
  border-radius: 25px;
}
.circleBaseWhiteBackground {
  
  
  border-radius: 25px;
  background-color: #FFF7F2;
}
.circleAccentLightBackground {
  
  
  border-radius: 50px;
  background-color: #952376;
}
.circleAccentBeigeBackground {
  
  
  border-radius: 50px;
  background-color: #F1E7E1;
}
.circleAccentOrangeBackground {
  
  
  border-radius: 50px;
  background-color: #E46936;
}
.roundedAccentDarkBackground {
  
  
  border-radius: 3px;
  background-color: #2B1665;
}
.circleAccentDarkBorderedAccentBeigeBackground {
  
  
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #2B1665;
  background-color: #F1E7E1;
}
.circleAccentDarkBorderedClearBackground {
  
  
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #2B1665;
}
.accentLightBorderedAccentBeigeBackground {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #952376;
  background-color: #F1E7E1;
}.BigCaslonRg32 {font-family: "Big-Caslon";
  font-size: 2rem;
  font-weight: 400;}
.BigCaslonRg24 {font-family: "Big-Caslon";
  font-size: 1.5rem;
  font-weight: 400;}
.BigCaslonRg20 {font-family: "Big-Caslon";
  font-size: 1.25rem;
  font-weight: 400;}
.BigCaslonRg18 {font-family: "Big-Caslon";
  font-size: 1.125rem;
  font-weight: 400;}
.BigCaslonRg14 {font-family: "Big-Caslon";
  font-size: 0.875rem;
  font-weight: 400;}
.BigCaslonBd14 {font-family: "Big-Caslon";
  font-size: 0.875rem;
  font-weight: 700;}
.AkkuratLLRg42 {font-family: "AkkuratLL-Regular";
  font-size: 2.625rem;
  font-weight: 400;}
.AkkuratLLRg18 {font-family: "AkkuratLL-Regular";
  font-size: 1.125rem;
  font-weight: 400;}
.AkkuratLLRg16 {font-family: "AkkuratLL-Regular";
  font-size: 1rem;
  font-weight: 400;}
.AkkuratLLRg14 {font-family: "AkkuratLL-Regular";
  font-size: 0.875rem;
  font-weight: 400;}
.AkkuratLLRg12 {font-family: "AkkuratLL-Regular";
  font-size: 0.75rem;
  font-weight: 400;}
.AkkuratLLBld18 {font-family: "AkkuratLL-Regular";
  font-size: 1.125rem;
  font-weight: 700;}
.AkkuratLLRg30 {font-family: "AkkuratLL-Regular";
  font-size: 1.875rem;
  font-weight: 400;}.BigCaslonRg32_accentDark {@extend .BigCaslonRg32;  color: #2B1665;
  text-transform: initial;}
.BigCaslonRg32_baseWhite {@extend .BigCaslonRg32;  color: #FFF7F2;
  text-transform: initial;}
.BigCaslonRg24_accentDark {@extend .BigCaslonRg24;  color: #2B1665;
  text-transform: initial;}
.BigCaslonRg24_baseWhite {@extend .BigCaslonRg24;  color: #FFF7F2;
  text-transform: initial;}
.BigCaslonRg18_accentDark {@extend .BigCaslonRg18;  color: #2B1665;
  text-transform: initial;}
.BigCaslonRg18_baseWhite {@extend .BigCaslonRg18;  color: #FFF7F2;
  text-transform: initial;}
.BigCaslonRg14_accentDark {@extend .BigCaslonRg14;  color: #2B1665;
  text-transform: initial;}
.BigCaslonBd14_accentDark_Uc {@extend .BigCaslonBd14;  color: #2B1665;
  text-transform: uppercase;}
.BigCaslonBd14_accentYellow_Uc {@extend .BigCaslonBd14;  color: #ED9A00;
  text-transform: uppercase;}
.BigCaslonRg14_accentDark_cap {@extend .BigCaslonRg14;  color: #2B1665;
  text-transform: uppercase;}
.BigCaslonRg20_accentDark {@extend .BigCaslonRg20;  color: #2B1665;
  text-transform: initial;}
.BigCaslonRg20_baseBlack_cap {@extend .BigCaslonRg20;  color: #333333;
  text-transform: uppercase;}
.AkkuratLLRg42_accentDark_cap {@extend .AkkuratLLRg42;  color: #2B1665;
  text-transform: uppercase;}
.AkkuratLLRg30_accentDark_cap {@extend .AkkuratLLRg30;  color: #2B1665;
  text-transform: initial;}
.AkkuratLLRg18_baseGray {@extend .AkkuratLLRg18;  color: #989898;
  text-transform: initial;}
.AkkuratLLRg18_accentDark {@extend .AkkuratLLRg18;  color: #2B1665;
  text-transform: initial;}
.AkkuratLLRg18_accentDark_cap {@extend .AkkuratLLRg18;  color: #2B1665;
  text-transform: uppercase;}
.AkkuratLLRg18_baseWhite {@extend .AkkuratLLRg18;  color: #FFF7F2;
  text-transform: initial;}
.AkkuratLLRg18_accentYellow {@extend .AkkuratLLRg18;  color: #ED9A00;
  text-transform: initial;}
.AkkuratLLRg18_accentOrange {@extend .AkkuratLLRg18;  color: #E46936;
  text-transform: initial;}
.AkkuratLLRg16_accentDark {@extend .AkkuratLLRg16;  color: #2B1665;
  text-transform: initial;}
.AkkuratLLRg16_baseWhite {@extend .AkkuratLLRg16;  color: #FFF7F2;
  text-transform: initial;}
.AkkuratLLRg16_accentOrange {@extend .AkkuratLLRg16;  color: #E46936;
  text-transform: initial;}
.AkkuratLLRg16_accentYellow {@extend .AkkuratLLRg16;  color: #ED9A00;
  text-transform: initial;}
.AkkuratLLRg16_baseWarning {@extend .AkkuratLLRg16;  color: #FF2A00;
  text-transform: initial;}
.AkkuratLLRg16_baseLink {@extend .AkkuratLLRg16;  color: #1173FF;
  text-transform: initial;}
.AkkuratLLRg14_baseGray {@extend .AkkuratLLRg14;  color: #989898;
  text-transform: initial;}
.AkkuratLLRg14_baseWhite {@extend .AkkuratLLRg14;  color: #FFF7F2;
  text-transform: initial;}
.AkkuratLLRg14_accentDark {@extend .AkkuratLLRg14;  color: #2B1665;
  text-transform: initial;}
.AkkuratLLRg14_accentOrange {@extend .AkkuratLLRg14;  color: #E46936;
  text-transform: initial;}
.AkkuratLLRg14_accentYellow {@extend .AkkuratLLRg14;  color: #ED9A00;
  text-transform: initial;}
.AkkuratLLRg14_baseWarning {@extend .AkkuratLLRg14;  color: #FF2A00;
  text-transform: initial;}
.AkkuratLLRg12_accentDark {@extend .AkkuratLLRg12;  color: #2B1665;
  text-transform: initial;}
.AkkuratLLRg12_baseWhite {@extend .AkkuratLLRg12;  color: #FFF7F2;
  text-transform: initial;}
.AkkuratLLBld18_baseWhite {@extend .AkkuratLLBld18;  color: #FFF7F2;
  text-transform: initial;}
.AkkuratLLBld18_accentDark {@extend .AkkuratLLBld18;  color: #2B1665;
  text-transform: initial;}
.AkkuratLLBld18_accentYellow {@extend .AkkuratLLBld18;  color: #ED9A00;
  text-transform: initial;}
.AkkuratLLBld18_baseWhite_cap {@extend .AkkuratLLBld18;  color: #FFF7F2;
  text-transform: uppercase;}
.AkkuratLLBld18_accentDark_cap {@extend .AkkuratLLBld18;  color: #2B1665;
  text-transform: uppercase;}
.AkkuratLLBld18_accentOrange_cap {@extend .AkkuratLLBld18;  color: #E46936;
  text-transform: uppercase;}
.AkkuratLLBld18_accentYellow_cap {@extend .AkkuratLLBld18;  color: #ED9A00;
  text-transform: uppercase;}.buttonPrimaryStandard {
  &:active {
    @extend .BigCaslonRg18_baseWhite;
background-color: #2B1665;
  }
  
  @extend .BigCaslonRg18_accentDark;
@extend .circleClearBackground;
}
.buttonPrimaryClear {
  &:active {
    @extend .AkkuratLLRg16_baseWhite;
background-color: #952376;
  }
  
  @extend .AkkuratLLRg16_accentDark;
@extend .base;
}
.buttonAccentYellowClear {
  &:active {
    @extend .AkkuratLLRg16_accentYellow;
  }
  
  @extend .AkkuratLLRg16_accentYellow;
@extend .base;
}
.buttonAccentDarkClear {
  &:active {
    @extend .AkkuratLLRg16_accentDark;
  }
  
  @extend .AkkuratLLRg16_accentDark;
@extend .accentLightBorderedClearBackground;
}
.buttonAccentOrangeClear {
  &:active {
    @extend .AkkuratLLRg16_accentOrange;
  }
  
  @extend .AkkuratLLRg16_accentOrange;
@extend .base;
}
.buttonPrimaryAccentDarkBordered {
  &:active {
    @extend .AkkuratLLBld18_baseWhite_cap;
background-color: #2B1665;
  }
  
  @extend .AkkuratLLBld18_accentDark_cap;
@extend .roundedAccentDarkBorderedClearBackground;
}
.buttonPrimaryAccentDark {
  &:active {
    @extend .AkkuratLLBld18_baseWhite_cap;
  }
  
  @extend .AkkuratLLBld18_baseWhite_cap;
@extend .roundedAccentDarkBackground;
}
.buttonPrimaryAccentYellow {
  &:active {
    @extend .AkkuratLLBld18_accentYellow_cap;
background-color: #FFF7F2;
  }
  &:disabled {
    background-color: #989898;
  }
  @extend .AkkuratLLBld18_baseWhite_cap;
@extend .roundedAccentYellowBorderedAccentYellowBackground;
}
.buttonMenuNavStandard {
  &:active {
    @extend .AkkuratLLRg18_accentDark_cap;
background-color: #EA909D;
  }
  
  @extend .AkkuratLLRg18_accentDark_cap;
@extend .circleClearBackground;
}
.buttonItemNavStandard {
  &:active {
    @extend .AkkuratLLRg18_accentDark_cap;
  }
  
  @extend .AkkuratLLRg18_accentDark_cap;
@extend .accentLightBorderedAccentBeigeBackground;
}
.buttonSecondaryAccentDark {
  &:active {
    @extend .BigCaslonRg32_baseWhite;
background-color: #2B1665;
  }
  
  @extend .BigCaslonRg32_accentDark;
background-color: #FFF7F2;
@extend .circleClearBackground;
}
.buttonTertiary {
  
  
  @extend .AkkuratLLRg14_accentDark;
}
.buttonTertiaryBordered {
  
  
  @extend .AkkuratLLRg14_accentDark;
@extend .accentDarkBorderedClearBackground;
}
.buttonTertiaryAccentYellow {
  
  
  @extend .AkkuratLLBld18_accentYellow_cap;
}
.buttonTertiaryAccentDark {
  
  
  @extend .AkkuratLLRg18_accentDark_cap;
}
.buttonTertiaryLink {
  
  
  @extend .AkkuratLLRg16_baseLink;
}
.buttonTertiaryCircle {
  &:active {
    @extend .AkkuratLLRg12_baseWhite;
  }
  
  @extend .AkkuratLLRg12_baseWhite;
@extend .circleAccentLightBackground;
}
.buttonTertiaryRadio {
  &:active {
    @extend .AkkuratLLRg18_baseWhite;
background-color: #2B1665;
  }
  
  @extend .AkkuratLLRg18_accentDark;
@extend .circleAccentDarkBorderedAccentBeigeBackground;
}
.buttonCheckbox {
  &:active {
    @extend .AkkuratLLRg14_baseWhite;
background-color: #ED9A00;
  }
  
  @extend .AkkuratLLRg14_accentYellow;
@extend .circleAccentYellowBorderedClearBackground;
}.primary {
@extend .AkkuratLLRg14_accentDark;
 &::placeholder {
    @extend .AkkuratLLRg14_baseGray;
  }
}
.secondary {
@extend .AkkuratLLRg14_accentDark;
 &::placeholder {
    @extend .AkkuratLLRg14_baseGray;
  }
}